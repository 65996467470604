// Importing all the Necessary Modules from Node Module Folder
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import "./style.css";
//import Textures from "./Textures.js";
//import Materials from "./Materials.js";
import Lights from "./Lights.js";

// Creating a Three JS Scene
const scene = new THREE.Scene();
const canvas = document.querySelector("canvas.webgl");

// Creating a sizes object of screen width and height
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

// On Resize Event
window.addEventListener("resize", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);
});

// On Orientation Change Event
window.addEventListener("orientationchange", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);
});

// On Double Click Mouse Event
window.addEventListener("dblclick", () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
});

window.addEventListener("fullscreenchange", () => {
  if (document.fullscreenElement) {
    console.log("Entered Full Screen");
  } else {
    console.log("Exited Full Screen");
  }
});

let visibleS = true; // visible state of Website Tab (true = visible, false = hidden)
// On Visibility Change Event
document.addEventListener("visibilitychange", () => {
  if (document.hidden) {
    visibleS = false;
  } else {
    visibleS = true;
  }
});

// Escape Key Event
document.addEventListener("keydown", (e) => {
  if (e.key === "Escape") {
    document.exitFullscreen();
  }
});

// (Click to Start Page Event)
const blocker = document.getElementById("blocker");
const instructions = document.getElementById("instructions");
const soundContainer = document.querySelector(".sound-container");
const checkBox = document.querySelector(".switch-checkbox");
const music = document.querySelector(".music");
const clickSound = document.querySelector(".click");
const droneSound = document.querySelector(".drone");
const glassSound = document.querySelector(".glass");
const backButton = document.querySelector(".back-button");
const logo = document.querySelector(".logo");
const thinkLogo = document.querySelector(".think-drones-logo");
const slowGlassSound = document.querySelector(".glass-slow");
instructions.addEventListener("click", function () {
  soundContainer.style.display = "flex";
  checkBox.checked = true;
  music.play();
  instructions.style.display = "none";
  blocker.style.display = "none";
});

const box1 = document.querySelector(".box1");

// (Full Screen Text Event) (Mac OS X OR Windows)
const fullScreen = document.getElementById("Full-screen");
if (navigator.userAgent.indexOf("Mac OS X") != -1) {
  fullScreen.innerHTML = "(Press Ctrl + ⌘ + F for best experience)";
} else if (navigator.userAgent.indexOf("Win64") != -1) {
  fullScreen.innerHTML = "(Press F11 for best experience)";
} else {
  fullScreen.innerHTML = "(Double tap for best experience)";
}

// Sound Trigger
// const soundTrigger = document.querySelector(".switch-checkbox");
// soundTrigger.addEventListener("toggle", () => {
//   if (soundTrigger.checked) {
//     console.log("Sound On");
//   }
//   if (!soundTrigger.checked) {
//     console.log("Sound Off");
//   }
// });

// Fog(color, near, far)
const fog = new THREE.Fog("#abaeb0", 1, 100);
scene.fog = fog; // Adding Fog to the scene

// Texture Object From Textures.js
//const Texture = new Textures();

// Perspective Camera(fov(deg), aspect ratio, near, far)
const camera = new THREE.PerspectiveCamera(
  22.5,
  sizes.width / sizes.height,
  0.01,
  1000
);
scene.add(camera); // Adding Camera to the scene

// Setiing up Draco Loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("draco/");

// const progressBar = document.getElementById("progress-bar-container");

// Loading Manager
const loadingManager = new THREE.LoadingManager();

// On Start Event - When Loading Starts
loadingManager.onStart = (url, item, total) => {
  // console.log(`Started loading file: ${url}.`);
};

const progressBarContainer = document.querySelector(".progress-bar-container");

const fullScreenLoading = document.getElementById("Full-screen-loading");
let loadValue = 0;
// On Progress Event - When Loading Progresses
loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
  // console.log(`Started loading file: ${url}.`);
  // console.log(`Loaded ${itemsLoaded} of ${itemsTotal} files.`);
  loadValue = (itemsLoaded / itemsTotal) * 100;
  // console.log(loadValue);
  fullScreenLoading.innerHTML = Math.round(loadValue) + "%";
};

// On Load Event - When Loading Ends
loadingManager.onLoad = () => {
  // console.log("Loading complete!");
  progressBarContainer.style.display = "none";
  tick(); // Calling tick function
  // Play animation on Click to Start
  window.addEventListener("click", () => {
    action1.play();
    thinkLogo.style.display = "flex";
    logo.style.display = "flex";
  });
};

// Setting up GLTF Loader
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);
const loader = new RGBELoader(loadingManager);

// Material Object From Materials.js
//const Material = new Materials();

// HDRI Texture Load
const hdrTextureURL = new URL("sky.hdr", import.meta.url);
loader.load(hdrTextureURL, (texture) => {
  texture.mapping = THREE.EquirectangularReflectionMapping;
  scene.background = texture;
  scene.environment = texture;
});

// Landscape Model Load
gltfLoader.load("Landscape12.glb", (gltf) => {
  scene.add(gltf.scene);

  /*gltf.scene.traverse((child) => {
    if (child.name === "Landscape") {
      child.material = Material.landscapeMat;
    }
  });*/
});

let Position1Json = null; // Tree (Without Leaf) Json File
let Position2Json = null; // Tree (With Leaf) Json File
let search = null;
let search1 = null;
let clone = null;
let clone1 = null;
let clone2 = null;
let trunk = null;

fetch("./tree1Position.json")
  .then((response) => response.json())
  .then((json) => {
    Position1Json = json;
    fetch("./tree2Position.json")
      .then((response) => response.json())
      .then((json) => {
        Position2Json = json;
        gltfLoader.load("treeLeaf8.glb", (gltf) => {
          gltf.scene.traverse((child) => {
            if (child.name === "Tree1Trunk") {
              trunk = child;
            }
          });

          scene.add(gltf.scene);
          // for loop from 3 to 252
          for (let i = 2; i < 253; i++) {
            if (i < 10) search1 = "tree2.00" + i;
            else if ((i >= 10) & (i < 100)) search1 = "tree2.0" + i;
            else search1 = "tree2." + i;
            if (Position2Json[search1]) {
              clone1 = gltf.scene.children[0].clone();
              clone2 = gltf.scene.children[1].clone();
              clone1.position.set(
                Position2Json[search1].x,
                Position2Json[search1].y,
                Position2Json[search1].z
              );
              clone2.position.set(
                Position2Json[search1].x,
                Position2Json[search1].y,
                Position2Json[search1].z
              );

              scene.add(clone1, clone2);
            }
          }
          for (let i = 3; i < 252; i++) {
            if (i < 10) search = "tree1.00" + i;
            else if ((i >= 10) & (i < 100)) search = "tree1.0" + i;
            else search = "tree1." + i;
            if (Position1Json[search]) {
              clone = trunk.clone();
              clone.position.set(
                Position1Json[search].x,
                Position1Json[search].y,
                Position1Json[search].z
              );
              scene.add(clone);
            }
          }
        });
      });
  });

let MonitorMidText1Mesh = null; // Begin Drone Activation Sequence Button
let MonitorMidText2Mesh = null; // Exit Button

let MidText1Mesh = null; // Click Here to Scout
let MidText3Mesh = null; //  Replay

let Exit1TextMesh = null; // Exit Showcase Button 1
let Exit2TextMesh = null; // Exit SHowcase Button 2

let GlassText1Mesh = null; // Infilteration Site Identified
let GlassText2Mesh = null; // Clickon WINDOW to Breach

let EndText1Mesh = null; // Mission Accomplished
let EndText2Mesh = null; // Replay Simulation
let EndText3Mesh = null; // Exit

// Loading Monitor + All the Text Content
gltfLoader.load("Monitor9.gltf", (gltf) => {
  scene.add(gltf.scene);
  gltf.scene.traverse((child) => {
    if (child.name === "Text2") {
      MonitorMidText1Mesh = child;
      child.material = new THREE.MeshBasicMaterial({
        color: "black",
      });
    }
    if (child.name === "Text3") {
      MonitorMidText2Mesh = child;
      child.material = new THREE.MeshBasicMaterial({
        color: "black",
      });
    }
    if (child.name === "Text4") {
      child.material = new THREE.MeshBasicMaterial({
        color: "Black",
      });

      GlassText1Mesh = child;
    }

    if (child.name === "Text5") {
      child.material = new THREE.MeshBasicMaterial({
        color: "Black",
      });
      GlassText2Mesh = child;
    }

    if (child.name === "Text6") {
      // child.material = new THREE.MeshBasicMaterial({
      //   color: "black",
      // });
      MidText1Mesh = child;
      //MidText1Mesh.material.color.setHex(0xffffff);
    }
    if (child.name === "Text8") {
      child.material = new THREE.MeshBasicMaterial({
        color: "Black",
      });
      MidText3Mesh = child;
    }
    if (child.name === "Text9") {
      child.material = new THREE.MeshBasicMaterial({
        color: "Black",
      });
      EndText1Mesh = child;
    }
    if (child.name === "Text10") {
      child.material = new THREE.MeshBasicMaterial({
        color: "Black",
      });
      EndText2Mesh = child;
    }
    if (child.name === "Text11") {
      child.material = new THREE.MeshBasicMaterial({
        color: "Black",
      });
      EndText3Mesh = child;
    }
  });
  // Setiing the Visibility of the Text Content to False
  GlassText1Mesh.visible = false;
  GlassText2Mesh.visible = false;
  EndText1Mesh.visible = false;
  EndText2Mesh.visible = false;
  EndText3Mesh.visible = false;
});

// Array of Rifle Clone
let rifleClone = [];
// Loading 1 Rifle for Military Tent and cloning it to other positions in the scene
gltfLoader.load("testRifle2-1.glb", (gltf) => {
  scene.add(gltf.scene);
  rifleClone[0] = gltf.scene.children[0].clone();
  rifleClone[0].position.set(-8.6676, 1.5696, 21.114);
  scene.add(rifleClone[0]);
  rifleClone[1] = gltf.scene.children[0].clone();
  rifleClone[1].position.set(-8.652, 1.5696, 20.849);
  scene.add(rifleClone[1]);
  rifleClone[2] = gltf.scene.children[0].clone();
  rifleClone[2].position.set(-8.6521, 1.5696, 17.815);
  scene.add(rifleClone[2]);
  rifleClone[3] = gltf.scene.children[0].clone();
  rifleClone[3].position.set(-8.6673, 1.5696, 17.55);
  scene.add(rifleClone[3]);
  rifleClone[4] = gltf.scene.children[0].clone();
  rifleClone[4].position.set(-8.6974, 1.5696, 17.309);
  scene.add(rifleClone[4]);
});

let PlaneMesh = null; // Main Camera
let PlaneMesh2 = null; // Baby Camera
let PlaneMesh3 = null; // Warehouse Camera
let PlaneMesh4 = null; // Carrier 360 Camera
let PlaneMesh5 = null; // Baby 360 Camera
let GlassMesh = null; // Hidden Glass Overlay on Broken Glasses

let mixer = null; // Animation Mixer for Main Camera+Drone+BabyDrone+BabyCamera
let mixer2 = null; // Animation Mixer for Camera+Features of Carrier 360
let mixer3 = null; // Animation Mixer for Camera+Features of baby 360
let mixer4 = null; // Animation Mixer for Arrow
let action1 = null; // Animation Action for Main Camera+Drone+BabyDrone+BabyCamera
let action2 = null; // Animation Action for Camera+Features of Carrier 360
let action3 = null; // Animation Action for Camera+Features of baby 360
let action4 = null; // Animation Action for Arrow

// Features List for Enabling visibility of the features
const featuresList = [];
const featuresList2 = [];

let t = true;

// Loading Arrow Model + Animation
gltfLoader.load("Arrow3.glb", (gltf) => {
  const model = gltf.scene;
  scene.add(model);
  gltf.scene.traverse((child) => {
    if (child.name === "Arrow") {
      child.material = new THREE.MeshBasicMaterial({
        color: "yellow",
      });
    }
  });
  mixer4 = new THREE.AnimationMixer(model);
  action4 = mixer4.clipAction(gltf.animations[0]);
  // console.log(gltf.animations);
});

// Loading Baby 360 Camera Animation + Features Animation
gltfLoader.load("baby362.glb", (gltf) => {
  const model = gltf.scene;
  scene.add(model);
  mixer3 = new THREE.AnimationMixer(model);
  action3 = mixer3.clipAction(gltf.animations[0]);

  gltf.scene.traverse((child) => {
    if (child.name === "CameraMesh5") {
      PlaneMesh5 = child;
    }
    if (child.name.startsWith("Text")) {
      featuresList2.push(child);
      child.material = new THREE.MeshBasicMaterial({
        color: "black",
      });
    }
    if (child.name.startsWith("CO")) {
      featuresList2.push(child);
    }
    if (child.name === "Exit2") {
      child.material = new THREE.MeshBasicMaterial({
        //color: "Black",
      });
      Exit2TextMesh = child;
      // featuresList2.push(child);
      child.visible = false;
    }
  });
  // Setting the Visibility of the Features to False
  featuresList2.forEach((feature) => {
    feature.visible = false;
  });
});

// Loading Carrier 360 Camera Animation + Features Animation
gltfLoader.load("CS362.glb", (gltf) => {
  const model = gltf.scene;
  scene.add(model);
  mixer2 = new THREE.AnimationMixer(model);
  action2 = mixer2.clipAction(gltf.animations[0]);
  gltf.scene.traverse((child) => {
    if (child.name === "CameraMesh4") {
      PlaneMesh4 = child;
    }
    if (child.name.startsWith("Text")) {
      featuresList.push(child);
    }
    if (child.name.startsWith("CO")) {
      featuresList.push(child);
    }
    if (child.name === "Back") {
      // child.visible = false;
      child.material = new THREE.MeshBasicMaterial({
        color: "Black",
      });
      Exit1TextMesh = child;
      // featuresList.push(child);
      child.visible = false;
    }
    if (child.name === "Exit") {
      child.visible = false;
    }
  });

  // Setting the Visibility of the Features to False
  featuresList.forEach((feature) => {
    feature.visible = false;
  });
});

// List of All Broken Glasses
const glassList = [];

// // Loading Main Drone + Baby Drone + Main Camera + baby Camera + Broken Glasses + Window Hide
gltfLoader.load("testDrone17.gltf", (gltf) => {
  const model = gltf.scene;
  mixer = new THREE.AnimationMixer(model);
  action1 = mixer.clipAction(gltf.animations[0]);
  gltf.scene.traverse((child) => {
    if (child.name === "CameraMesh1") {
      PlaneMesh = child;
    }
    if (child.name === "Cambaby") {
      PlaneMesh2 = child;
    }
    if (child.name === "CameraMesh2") {
      PlaneMesh3 = child;
    }
    if (child.name === "WindowHide") {
      GlassMesh = child;
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.5,
        side: THREE.DoubleSide,
      });
    }
    if (child.name.startsWith("Glass")) {
      glassList.push(child);
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.5,
        side: THREE.DoubleSide,
      });
    }
  });

  // Setting the Visibility of the Broken Glasses to False
  glassList.forEach((glass) => {
    glass.visible = false;
  });

  // Setting the Animation to Loop Once
  action1.setLoop(THREE.LoopOnce);
  scene.add(model);

  // Developer Controls for Play, Stop, Reset and Stop

  // window.addEventListener("keyup", (e) => {
  //   if (e.key === "p") {
  //     // console.log("Pressed Play");
  //     action1.play();
  //   }
  // });
  // window.addEventListener("keydown", (e) => {
  //   if (e.key === "s") {
  //     action1.stop();
  //     // action2.stop();
  //     mixer.time = 0;
  //   }
  // });
  // window.addEventListener("keydown", (e) => {
  //   if (e.key === "r") {
  //     action1.reset();
  //     // action2.reset();
  //     mixer.time = 0;
  //   }
  // });
  // window.addEventListener("keyup", (e) => {
  //   if (e.key === "t") {
  //     // console.log("Pressed Toggle");
  //     if (action1.isRunning()) {
  //       action1.paused = true;
  //       // action2.paused = true;
  //     } else {
  //       mixer.time = 0;
  //       action1.paused = false;
  //       // action2.paused = false;
  //     }
  //   }
  // });
});

let rifle2Clone = []; // Array of Clones of Rifle2
let tableClone = []; // Array of Clones of Table
let shelfClone = []; // Array of Clones of Shelf

// Loading Rifle2 + Table + Shelf + Tent + Boxes + Frames
gltfLoader.load("tent4.glb", (gltf) => {
  scene.add(gltf.scene);
  gltf.scene.traverse((child) => {
    if (child.name === "Table") {
      //child.material = Material.tableMat;
      tableClone[0] = child.clone();
      tableClone[0].position.set(-1.7016, 1.2547, 21.6);
      //tableClone[0].material = Material.tableMat;
      scene.add(tableClone[0]);
      tableClone[1] = child.clone();
      tableClone[1].position.set(-5.1216, 1.2548, 21.615);
      //tableClone[1].material = Material.tableMat;
      scene.add(tableClone[1]);
    }
    /*if (child.name === "BlackBox") {
      child.material = Material.blackBoxMat;
    }
    if (child.name === "WhiteBox") {
      child.material = Material.whiteBoxMat;
    }
    if (child.name === "BrownBox") {
      child.material = Material.brownBoxMat;
    }
    if (child.name === "Pallet") {
      child.material = Material.palletMat;
    }
    if (child.name === "Frame") {
      child.material = Material.frameMat;
    }
    if (child.name === "Cloth") {
      child.material = Material.tentMat;
    }
    if (child.name === "Pistol2") {
      child.material = Material.pistol2Mat;
    }*/
    if (child.name === "Rifle1") {
      //child.material = Material.rifle1Mat;
      rifle2Clone[0] = child.clone();
      rifle2Clone[0].position.set(-8.1871, 1.5313, 21.777);
      //rifle2Clone[0].material = Material.rifle1Mat;
      scene.add(rifle2Clone[0]);
      rifle2Clone[1] = child.clone();
      rifle2Clone[1].position.set(-8.1654, 1.5313, 16.883);
      //rifle2Clone[1].material = Material.rifle1Mat;
      scene.add(rifle2Clone[1]);
      rifle2Clone[2] = child.clone();
      rifle2Clone[2].position.set(-7.8193, 1.5313, 16.876);
      //rifle2Clone[2].material = Material.rifle1Mat;
      scene.add(rifle2Clone[2]);
    }
    //if (child.name === "Paper") {
    //  child.material = Material.paperMat;
    //}
    if (child.name === "Shelf") {
      //child.material = Material.shelfMat;
      shelfClone[0] = child.clone();
      shelfClone[0].position.set(-8.4139, 1.791, 21.503);
      shelfClone[0].rotation.z += Math.PI / 2;
      //shelfClone[0].material = Material.shelfMat;
      scene.add(shelfClone[0]);
    }
  });
});

// Loading WareHouse all Models
gltfLoader.load("ware8.glb", (gltf) => {
  scene.add(gltf.scene);
  gltf.scene.traverse((child) => {
    if (child.name === "Window") {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.5,
      });
    }
    /*if (child.name === "Walls") {
      const walltempMat = Material.wallsMat;
      walltempMat.side = THREE.DoubleSide;
      child.material = Material.wallsMat;
    }
    if (child.name === "WoodDestroyed") {
      child.material = Material.woodDestroyedMat;
    }
    if (child.name === "WoodPalette") {
      child.material = Material.woodPaletteMat;
    }
    if (child.name === "WindowPane") {
      child.material = Material.windowPaneMat;
    }
    if (child.name === "Roof") {
      const rooftempMat = Material.roofMat;
      rooftempMat.side = THREE.DoubleSide;
      child.material = Material.roofMat;
    }
    if (child.name === "Pillars") {
      child.material = Material.pillarsMat;
    }
    if (child.name === "Lamp") {
      child.material = Material.lampMat;
    }
    if (child.name === "Floor") {
      child.material = Material.floorMat;
    }
    if (child.name === "Door") {
      const doortempMat = Material.doorMat;
      doortempMat.side = THREE.DoubleSide;
      child.material = Material.doorMat;
    }
    if (child.name === "CrateSmall") {
      child.material = Material.crateSmallMat;
    }
    if (child.name === "CrateLong") {
      child.material = Material.crateLongMat;
    }
    if (child.name === "AWMgun") {
      child.material = Material.AWMGunMat;
    }
    if (child.name === "K98Kgun") {
      child.material = Material.K98KGunMat;
    }
    if (child.name === "MK14") {
      child.material = Material.MK14GunMat;
    }
    if (child.name === "GrenadeWarehouse") {
      child.material = Material.grenadeWarehouseMat;
    }*/
  });
});

const mouse = new THREE.Vector2();

// Mouse Move Event
window.addEventListener("mousemove", (e) => {
  mouse.x = (e.clientX / sizes.width) * 2 - 1;
  mouse.y = -(e.clientY / sizes.height) * 2 + 1;
});

let WindowClick = false; // Window Clicked

// Mouse Click Event for all the Text Options using raycaster

console.log(continue_1);
window.addEventListener("click", (e) => {
  console.log("1");
  if (currentIntersect || continue_1) {
    console.log("2");
    if (currentIntersect.object.name === "Text2" || continue_1) {
      clickSound.play();
      action4.paused = true;
      action1.paused = false;
      continue_1 = false;
    }
    if (currentIntersect.object.name === "WindowHide") {
      GlassMesh.visible = false;
      glassSound.play();
      clickSound.play();
      WindowClick = true;
      action1.paused = false;
      glassList.forEach((glass) => {
        glass.visible = true;
      });
      GlassText1Mesh.visible = false;
      GlassText2Mesh.visible = false;
    }
    if (currentIntersect.object.name === "Text3") {
      clickSound.play();
      window.location.href = "https://insidefpv.com/";
    }
    if (currentIntersect.object.name === "Text6") {
      clickSound.play();
      action1.paused = false;
    }
    if (currentIntersect.object.name === "Text8") {
      clickSound.play();
      action1.time = 400 / 24;
      action1.paused = false;
      checkpoint2 = false;
    }
    if (currentIntersect.object.name === "Back") {
      clickSound.play();
      exit = true;
      infinite1 = false;
      action1.paused = false;
      featuresList.forEach((feature) => {
        feature.visible = false;
      });
    }
    if (currentIntersect.object.name === "Exit2") {
      clickSound.play();
      droneSound.play();
      exit2 = true;
      infinite2 = false;
      action1.paused = false;
      featuresList2.forEach((feature) => {
        feature.visible = false;
      });
    }
    if (currentIntersect.object.name === "Text10") {
      clickSound.play();
      GlassMesh.visible = true;
      action1.paused = false;
      action1.time = 0;
      action2.time = 0;
      action3.time = 0;
      action4.time = 0;
      WindowClick = false;
      checkpoint1 = false;
      checkpoint2 = false;
      checkpoint3 = false;
      change = false;
      slowMo = false;
      exit = 0;
      infinite1 = false;
      exit2 = 0;
      infinite2 = false;
      GlassText1Mesh.visible = false;
      GlassText2Mesh.visible = false;
      EndText1Mesh.visible = false;
      EndText2Mesh.visible = false;
      EndText3Mesh.visible = false;
      HUD.style.opacity = "100";
      t = true;
      // HUD.style.display = "none";
      music.play();
    }
    if (currentIntersect.object.name === "Text11") {
      clickSound.play();
      window.location.href = "https://insidefpv.com/";
    }
  }
});

// Raycaster
const raycaster = new THREE.Raycaster();
raycaster.far = 5;

// Lights
const pointLight = new Lights().pointLight;
scene.add(pointLight);

// Rendering PART
const clock = new THREE.Clock();
const renderer = new THREE.WebGLRenderer({ canvas: canvas });
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 0.4;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
let previousTime = 0;
let currentIntersect = null; // having a value when an object intersects through the ray
let checkpoint1 = false; // Boolean variable for Checkpoint 1 - Monitor
let checkpoint2 = false; // Boolean variable for Checkpoint 2 - Scout
let checkpoint3 = false; // Boolean variable for Checkpoint 3 - Window
let slowMo = false; // Slow Motion Boolean Variable
let change = false;
let slowMoSound = false;
let exit = false; // Exit Carrier Featuers Boolean Variable
let exit2 = false; // Exit Baby Features Boolean Variable
let infinite1 = false; // Infinite Carrier Features Boolean variable
let infinite2 = false; // Infinite Baby Features Boolean variable
const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;

  if (MonitorMidText1Mesh) {
    raycaster.setFromCamera(mouse, camera);
    const objects = [
      MonitorMidText1Mesh,
      MonitorMidText2Mesh,
      MidText1Mesh,
      MidText3Mesh,
      EndText2Mesh,
      EndText3Mesh,
      GlassMesh,
    ];
    const intersects = raycaster.intersectObjects(objects);

    objects[0].material.color.set(0x000000);
    objects[1].material.color.set(0x000000);
    objects[2].material.color.set(0x000000);
    objects[3].material.color.set(0x000000);
    objects[4].material.color.set(0x000000);
    objects[5].material.color.set(0x000000);
    objects[6].material.color.set(0x000000);

    for (const object of objects) {
      if (object.name === "WindowHide") {
        object.material.color.set(0x000000);
      } else if (
        object.name === "Text1" ||
        object.name === "Text2" ||
        object.name === "Text3"
      ) {
        object.material.color.set("white");
      } else {
        object.material.color.set(0x000000);
      }
    }

    for (const intersect of intersects) {
      // console.log(intersect.object.name);
      intersect.object.material.color.set("yellow");
    }

    if (intersects.length) {
      if (currentIntersect === null) {
        // console.log("Mouse enter");
        currentIntersect = intersects[0];
      }
    } else {
      if (currentIntersect) {
        // console.log("Mouse leave");
        currentIntersect = null;
      }
    }
  }

  if (visibleS) {
    if (mixer) {
      mixer.update(deltaTime);
    }
    if (mixer2) {
      mixer2.update(deltaTime);
    }
    if (mixer3) {
      mixer3.update(deltaTime);
    }
    if (mixer4) {
      mixer4.update(deltaTime);
    }
    // At Frame 180 - Stop main Camera untill button pressed - CHeckpoint 1
    // Activate Arrow Animation
    if (action1.time > 180 / 24) {
      if (!checkpoint1) {
        box1.style.pointerEvents = "visible";
        action4.play();
        action4.time = 180 / 24;
        action1.paused = true;
        checkpoint1 = true;
        console.log(box1.style);
      }
    }
    // From 533 - 642 (HUD)
    if (action1.time > 553 / 24 && t) {
      var video = document.getElementById("HUD");
      video.play();
      HUD.style.display = "flex";
    }

    if (action1.time > 601 / 24 && t) {
      action1.time = 570 / 24;
      HUD.style.opacity = "0.5";
      t = false;
    }

    if (action1.time > 606 / 24) {
      HUD.style.opacity = "0.25";
    }

    if (action1.time > 642 / 24) {
      HUD.style.opacity = "0";
      HUD.style.display = "none";
    }

    // At 376 - Infinite Carrier Features
    if (PlaneMesh4 && action1.time > 376 / 24 && !exit) {
      action1.paused = true;
      backButton.style.display = "flex";
      droneSound.pause();
      infinite1 = true;
      action2.play();
      backButton.addEventListener("click", () => {
        if (!exit) {
          clickSound.play();
          exit = true;
          infinite1 = false;
          action1.paused = false;
          featuresList.forEach((feature) => {
            feature.visible = false;
          });
          backButton.style.display = "none";
        }
      });

      featuresList.forEach((feature) => {
        feature.visible = true;
      });
      camera.position.set(
        PlaneMesh4.position.x,
        PlaneMesh4.position.y,
        PlaneMesh4.position.z
      );
      camera.rotation.set(
        PlaneMesh4.rotation.x,
        PlaneMesh4.rotation.y,
        PlaneMesh4.rotation.z
      );
    }

    // At 1083 - Checkpoint 2
    if (action1.time > 1083 / 24) {
      if (!checkpoint2) {
        droneSound.pause();
        action1.paused = true;
        checkpoint2 = true;
      }
    }

    // At 1170 - Infinte Baby Features
    if (PlaneMesh5 && action1.time > 1170 / 24 && !exit2) {
      action1.paused = true;
      infinite2 = true;
      action3.play();
      droneSound.pause();
      featuresList2.forEach((feature) => {
        feature.visible = true;
      });
      backButton.style.display = "flex";
      backButton.addEventListener("click", () => {
        if (!exit2) {
          clickSound.play();
          droneSound.play();
          exit2 = true;
          infinite2 = false;
          action1.paused = false;
          featuresList2.forEach((feature) => {
            feature.visible = false;
          });
          backButton.style.display = "none";
        }
      });

      if (action3.time > 25) {
        action3.time = 0;
      }
      camera.position.set(
        PlaneMesh5.position.x,
        PlaneMesh5.position.y,
        PlaneMesh5.position.z
      );

      camera.rotation.set(
        PlaneMesh5.rotation.x,
        PlaneMesh5.rotation.y,
        PlaneMesh5.rotation.z
      );
    }

    // From 1630 - 1659 - Main Camera Animation
    if (
      action1.time > 1630 / 24 &&
      action1.time < 1659 / 24 &&
      WindowClick &&
      !change
    ) {
      camera.position.set(
        PlaneMesh.position.x,
        PlaneMesh.position.y,
        PlaneMesh.position.z
      );
      camera.rotation.set(
        PlaneMesh.rotation.x,
        PlaneMesh.rotation.y,
        PlaneMesh.rotation.z
      );
    }

    // At 1630 - Checkpoint 3
    if (action1.time > 1630 / 24 && !checkpoint3) {
      GlassMesh.visible = true;
      GlassText1Mesh.visible = true;
      GlassText2Mesh.visible = true;
      action1.paused = true;
      checkpoint3 = true;
    }

    // From 1660 - 1690 (Warehouse Camera) - Slow Motion
    if (
      PlaneMesh3 &&
      action1.time > 1660 / 24 &&
      action1.time < 1690 / 24 &&
      !slowMo
    ) {
      slowMo = true;
      change = true;
      action1.time = 1645 / 24;
      mixer.timeScale = 0.2;
      camera.position.set(
        PlaneMesh3.position.x,
        PlaneMesh3.position.y,
        PlaneMesh3.position.z
      );
      camera.rotation.set(
        PlaneMesh3.rotation.x,
        PlaneMesh3.rotation.y,
        PlaneMesh3.rotation.z
      );
      if (!slowMoSound) {
        slowGlassSound.play();
        slowMoSound = true;
      }
    }

    // Bringing the camera animation back to normal Speed
    if (action1.time > 1660 / 24) {
      mixer.timeScale = 1;
    }

    // From 1690 - 1960 - Baby Camera
    if (action1.time >= 1690 / 24 && action1.time < 1960 / 24) {
      mixer.timeScale = 1;
      camera.position.set(
        PlaneMesh2.position.x,
        PlaneMesh2.position.y,
        PlaneMesh2.position.z
      );
      camera.rotation.set(
        PlaneMesh2.rotation.x,
        PlaneMesh2.rotation.y,
        PlaneMesh2.rotation.z
      );
    }

    // From 1960 to 2030 - Warehouse Camera
    if (action1.time > 1960 / 24 && action1.time <= 2030 / 24) {
      // if (action1.time > 2029 / 24) {
      //   action1.paused = true;
      // }
      EndText1Mesh.visible = true;
      EndText2Mesh.visible = true;
      EndText3Mesh.visible = true;
      camera.position.set(
        PlaneMesh3.position.x,
        PlaneMesh3.position.y,
        PlaneMesh3.position.z
      );
      camera.rotation.set(
        PlaneMesh3.rotation.x,
        PlaneMesh3.rotation.y,
        PlaneMesh3.rotation.z
      );
    }

    //  1360 to 1631 : Baby Camera Animation
    if (PlaneMesh2 && action1.time > 1360 / 24 && action1.time < 1631 / 24) {
      camera.position.set(
        PlaneMesh2.position.x,
        PlaneMesh2.position.y,
        PlaneMesh2.position.z
      );
      camera.rotation.set(
        PlaneMesh2.rotation.x,
        PlaneMesh2.rotation.y,
        PlaneMesh2.rotation.z
      );
    }
    // Rest other than Infinite for Main Camera
    else if (
      PlaneMesh &&
      action1.time >= 0 &&
      action1.time < 1360 / 24 &&
      !infinite1 &&
      !infinite2
    ) {
      if (exit) {
        // console.log("Hi");
        if (action1.time * 24 > 470) {
          droneSound.play();
        }
      }

      camera.position.set(
        PlaneMesh.position.x,
        PlaneMesh.position.y,
        PlaneMesh.position.z
      );
      if (!action1.paused) {
        camera.rotation.set(
          PlaneMesh.rotation.x - 0.05 * mouse.y,
          PlaneMesh.rotation.y + 0.05 * mouse.x,
          PlaneMesh.rotation.z
        );
      } else {
        camera.rotation.set(
          PlaneMesh.rotation.x,
          PlaneMesh.rotation.y,
          PlaneMesh.rotation.z
        );
      }
    }
    renderer.render(scene, camera);
    requestAnimationFrame(tick);
  }
};
